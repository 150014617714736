<template>
  <div id="distribution_container">
    <span v-if="!loading && columns.length" class="pan-head-icon-wrap">
      <span class="export-intip4 export-intip6" data-tool-tip="Export" @click="showOptions = true">
        <font-awesome-icon :icon="['far', 'ellipsis-h']" class="pan-head-icon" />
      </span>
      <div v-if="showOptions" class="download-options-modal">
        <div class="modal-header">
          <button class="close-btn" @click="showOptions = false">X</button>
        </div>
        <div class="options-content">
          <p>Download as:</p>
          <button class="option-btn" @click="handleDownload('pdf', 'distribution_container')">
            PDF
          </button>
          <button class="option-btn" @click="handleDownload('csv')">CSV</button>
        </div>
      </div>
    </span>
    <div class="chart-filters">
      <b-select
        :options="getReportTypeOptions"
        :selected="reportTypeValue"
        :class-name="'greyBackground'"
        @change="onChangeReportType"
      />
      <span class="pan-by">by</span>
      <b-select
        :options="groupByOptions"
        :selected="groupByValue"
        :class-name="'greyBackground'"
        @change="onChangeGroupBy"
      />
    </div>
    <div class="container-chart">
      <flip-donut-chart
        v-if="!loading && columns.length && displayChart === 'donut'"
        class="donut-chart"
        :columns="columns"
        :legend-headers="donutChart.legendHeaders"
        :value-type="numValueType"
        :show-legend-headers="donutChart.showLegendHeaders"
        :show-legend-values="donutChart.showLegendValues"
        :donut-slice-text-using="groupByValue === 'daypart' ? 'label' : 'ratio'"
      />
      <flip-multi-country-map-chart
        v-if="!loading && columns.length && displayChart === 'geo'"
        :map-country="mapCountry"
        :map-type="mapType"
        :map-data="columns"
        :value-key="reportTypeLabel"
        :value-type="numValueType"
      />
      <div v-else-if="!loading && !columns.length" class="txt-center">
        No data is available to show
      </div>
      <b-loading-spinner v-else-if="loading" class="txt-center" />
    </div>
  </div>
</template>

<script>
import { isBlank } from 'adready-api/helpers/common';
import isEqual from 'lodash/isEqual';
import { get } from 'vuex-pathify';
import { pageExportToPDF } from '@/util/utility-functions';
import advertiserReportsAPI from '~/api/advertiser-reports';
import { buildQueryString } from '~/helpers/global/url-helpers';
import * as CONSTANT from '~/constant';
import graphDataMixin from '~/components/mixins/graph-data-mixin';
import {
  DISTRIBUTION_KIND_OPTIONS,
  KIND_VIEWS,
  KIND_SPEND,
  KIND_EVENT,
  IMPRESSIONS,
  REVENUE,
  VISITS,
} from '~/constant';
import * as util from '~/util/utility-functions';

export default {
  name: 'DistributionChart',
  components: {
    FlipMultiCountryMapChart: () =>
      import(
        /* webpackChunkName: "multi-country-map-chart" */ '@/components/charts/flip-multi-country-map-chart.vue'
      ),
    FlipDonutChart: () =>
      import(/* webpackChunkName: "donut-chart" */ '@/components/charts/flip-donut-chart.vue'),
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    BSelect: () => import(/* webpackChunkName: "b-select" */ '~/components/elements/b-select.vue'),
  },

  mixins: [graphDataMixin],

  props: {
    initialGroupBy: {
      type: String,
      required: false,
      default: () => CONSTANT.DISTRIBUTION_GROUP_BY_OPTIONS[0].id,
    },
  },
  data() {
    return {
      CONSTANT,
      showOptions: false,
      pageExportToPDF,
      groupByValue: this.initialGroupBy,
      columns: [],
      donutChart: {
        legendHeaders: [],
        showLegendHeaders: false,
        showLegendValues: false,
        valueType: '',
      },
      reportTypeLabel: IMPRESSIONS,
    };
  },

  computed: {
    account: get('common/account'),
    displayChart() {
      const chartTypeHM = {
        state: 'geo',
        dma: 'geo',
        subcategories: 'geo',
      };
      return chartTypeHM[this.groupByValue] || 'donut';
    },
    mapCountry() {
      return this.account?.countryCode?.toUpperCase() || 'US';
    },
    mapType() {
      return this.groupByValue === 'subcategories' ? 'address' : this.groupByValue;
    },
    getReportTypeOptions() {
      const reportTypeSelections = [...DISTRIBUTION_KIND_OPTIONS];
      const eventOption = reportTypeSelections.find((opt) => opt?.id === KIND_EVENT);

      if (eventOption) {
        eventOption.name =
          this.selectedEvent.eventLabel === 'Video Completions'
            ? 'Completions'
            : this.selectedEvent.eventLabel;
        eventOption.children = this.selectedEvent.subEventLabels || [];
      }

      return reportTypeSelections
        .filter((rts) => rts.name !== 'Incremental Reach')
        .filter((rts) => !!rts.name.trim());
    },
    numValueType() {
      if (this.reportTypeValue === KIND_EVENT) {
        return util.getValueFormat(
          this.selectedEvent.isRevenueEvent ? REVENUE : this.reportTypeValue
        );
      }
      return util.getValueFormat(this.reportTypeValue);
    },
    groupByOptions() {
      let options = [...CONSTANT.DISTRIBUTION_GROUP_BY_OPTIONS];
      // if (!CONSTANT.ACCOUNTS_TO_SHOW.includes(this.account?.id)) {
      //   options = [];
      //   options = CONSTANT.DISTRIBUTION_GROUP_BY_OPTIONS.filter((item) => item.id !== 'channel');
      // }
      if (this.reportTypeValue === KIND_EVENT && !this.selectedEvent?.isFalseEvent) {
        const locVis = this.getReportTypeOptions.find((o) => o.id === KIND_EVENT);
        if (locVis && locVis.name === CONSTANT.LOC_VISITS) {
          util.insertAt(options, 1, CONSTANT.DISTRIBUTION_GROUP_BY_ADDRESSES);
        }
        options.push(CONSTANT.DISTRIBUTION_GROUP_BY_TIMES_EXPOSED);
      } else if (this.reportTypeValue === KIND_EVENT && this.selectedEvent?.isFalseEvent) {
        options = this.selectedEvent?.isVcrEvent
          ? [...CONSTANT.DISTRIBUTION_GROUP_BY_OPTIONS_VCR]
          : [...CONSTANT.DISTRIBUTION_GROUP_BY_OPTIONS_CTR];
      }
      if (!CONSTANT.ACCOUNTS_TO_SHOW.includes(this.account?.id)) {
        // options = [];
        options = options.filter((item) => item.id !== 'channel');
      }
      if (this.account?.countryCode?.toUpperCase() !== 'US') {
        options = options.filter((o) => o.id !== 'dma');
      }
      return options;
    },
  },
  watch: {
    initialGroupBy: {
      async handler(n, o) {
        if (n === o) {
          return;
        }
        try {
          await this.onChangeGroupBy(n);
        } catch (err) {
          console.error('error in updating groupby value in distribution ->', err);
          if (window.$sentry) {
            if (err._reported !== true) {
              window.$sentry.captureException(err);
              err._reported = true;
            }
          }
        }
      },
    },
    async selectedEvent(n, o) {
      if (!isEqual(n, o)) {
        const [firstOption] = this.getReportTypeOptions;
        const value = firstOption?.id || firstOption;
        const label = firstOption?.name || firstOption;
        this.reportTypeValue = value;
        this.reportTypeLabel = label;
        const groupByOptionsMap = this.groupByOptions.map((gbo) => gbo?.id);
        if (!groupByOptionsMap.includes(this.groupByValue)) {
          [this.groupByValue] = groupByOptionsMap;
          try {
            await this.onChangeReportType(value, label);
          } catch (err) {
            console.error('error watching selectedEvent distribution-chart', err);
            if (window.$sentry) {
              if (err._reported !== true) {
                window.$sentry.captureException(err);
                err._reported = true;
              }
            }
          }
        }
        this.updateDonutChartHeaders();
      }
    },
  },
  async mounted() {
    try {
      this.updateDonutChartHeaders();
      await this.loadGraphData(this.payload);
    } catch (err) {
      console.error('error mounting distribution-chart', err);
      if (window.$sentry) {
        if (err._reported !== true) {
          window.$sentry.captureException(err);
          err._reported = true;
        }
      }
    }
  },
  methods: {
    handleDownload(format, elementId = '') {
      this.showOptions = false;
      if (format === 'csv') {
        this.download();
      } else {
        this.pageExportToPDF(elementId);
      }
    },
    download() {
      if (!this.loading && !this.columns.length) {
        return;
      }

      const calPercent = (arr, rawData) => {
        const sum = arr.reduce((acc, val) => acc + val);
        const part = [];

        rawData.forEach((element, index) => {
          rawData[index] = [
            element[element.length - 2],
            element[element.length - 1],
            `${((element[element.length - 1] * 100) / sum).toFixed(1)}%`,
          ];
        });
        part.push(...rawData);
        return part;
      };
      try {
        const header = [...this.donutChart.legendHeaders];
        if (!header[0].includes('Percent')) header[0].push('Percent');
        const values = [];
        let lines = [];

        if (header[0][0] === 'State' || header[0][0] === 'DMA') {
          lines = [];
          this.columns.forEach((row) => {
            values.push(row.tally);
          });
          const columnsArray = this.columns.map((item) => {
            return [item.location, item.tally];
          });
          lines = [...calPercent(values, columnsArray)];
        } else if (header[0][0] === 'Address') {
          this.columns.forEach((row) => {
            values.push(row.tally);
          });
          const columnsArray = this.columns.map((item) => {
            return [item.location, item.tally];
          });
          lines = [...calPercent(values, columnsArray)];
        } else if (this.columns[0].length === 2) {
          this.columns.forEach((row) => {
            values.push(...row.slice(-1));
          });
          lines = [...calPercent(values, this.columns)];
        } else {
          lines = [...this.columns];
        }

        const csvData = [...header, ...lines];
        const fileName = `export_${header[0][1]}_by_${header[0][0]}.csv`;

        util.downloadCSVFile(csvData, fileName.replace(/ /g, '_').toLowerCase());
      } catch (err) {
        console.error('error downloading file ->', err);
        throw err;
      }
    },
    async onChangeReportType(value, label) {
      const payload = { ...this.payload };

      this.reportTypeValue = value;
      this.reportTypeLabel = label;

      const groupByOptionsMap = this.groupByOptions.map((gbo) => gbo?.id);
      if (!groupByOptionsMap.includes(this.groupByValue)) {
        [this.groupByValue] = groupByOptionsMap;
      }
      this.updateDonutChartHeaders();
      try {
        await this.loadGraphData(payload);
      } catch (err) {
        console.error('Error in updating distribution chart ->', err.message, err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
    async onChangeGroupBy(val) {
      this.groupByValue = val;
      this.updateDonutChartHeaders();

      try {
        await this.loadGraphData(this.payload);
      } catch (err) {
        console.error('Error in updating distribution chart ->', err.message, err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
    async loadGraphData(payload) {
      this.loading = true;
      payload = { ...payload };

      if (!this.groupByOptions.map((gbo) => gbo?.id).includes(this.groupByValue)) {
        this.groupByValue = this.groupByOptions[0]?.id;
      }

      delete payload.compareStartDate;
      delete payload.compareEndDate;
      payload.groupBy = this.groupByValue;

      if (
        this.reportTypeValue === CONSTANT.LOC_VISITS &&
        this.groupByValue === CONSTANT.DISTRIBUTION_GROUP_BY_ADDRESSES?.id
      ) {
        delete payload.subCategory;
      }

      const rTV = this.reportTypeValue;
      try {
        switch (rTV) {
          case KIND_VIEWS:
          case KIND_SPEND:
            delete payload.pixel;
            delete payload.event;
            delete payload.category;
            delete payload.subCategory;
            delete payload.showUniques;
            delete payload.attribution;
            delete payload.methodology;
            delete payload.conversionWindow;

            payload.kind = rTV;

            await this.fetchDistributionImpressions(payload);
            break;

          case KIND_EVENT:
            if (this.selectedEvent?.isFalseEvent) {
              payload.kind = this.selectedEvent.isVcrEvent ? 'videoCompletions' : 'clicks';
              await this.fetchDistributionImpressions(payload);
              break;
            }
            payload.kind = this.selectedEvent.isRevenueEvent ? REVENUE : VISITS;

            await this.fetchDistributionConversions(payload);
            break;

          default:
            break;
        }
      } catch (err) {
        this.columns = [];
        console.error('error fetching distribution API ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
        throw err;
      } finally {
        this.loading = false;
      }
    },
    validateParams(payload, advertiser, extraKeys = []) {
      const advertiserId = advertiser ? advertiser.id : null;
      if (isBlank(advertiserId)) {
        return false;
      }
      const keys = ['kind', 'client', 'advertiser', 'startDate', 'endDate', ...extraKeys];
      return !util.isAnyBlank(payload, keys);
    },
    async fetchDistributionConversions(payload) {
      try {
        const keys = ['xandrId', 'pixel', 'category'];
        const dataValidated = this.validateParams(payload, this.advertiser, keys);
        let data = [];
        if (dataValidated) {
          data = await advertiserReportsAPI.conversionsDistribution(
            this.advertiser?.id,
            buildQueryString(payload)
          );
        }
        if (this.loading) {
          this.processResults(data);
        }
      } catch (err) {
        console.error('error in fetching conversions distribution ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
        throw err;
      }
    },
    async fetchDistributionImpressions(payload) {
      try {
        const data = await advertiserReportsAPI.impressionsDistribution(
          this.advertiser?.id,
          buildQueryString(payload)
        );
        if (this.loading) {
          this.processResults(data);
        }
      } catch (err) {
        console.error('error in fetching impressions distribution ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
        throw err;
      }
    },
    async processResults(res) {
      const nColumns = [];

      res.forEach((item) => {
        if (
          this.groupByValue.toLowerCase() === 'state' ||
          this.groupByValue.toLowerCase() === 'dma'
        ) {
          nColumns.push({ location: item.key, tally: item.value });
        } else if (this.groupByValue.toLowerCase() === 'subcategories') {
          const coordinates = item.extra.split(',', 2);
          nColumns.push({
            location: item.key,
            tally: item.value,
            lat: coordinates[0],
            lng: coordinates[1],
          });
        } else if (this.groupByValue.toLowerCase() === 'daypart') {
          const daypartKey = util.formatHour(item.key);
          nColumns.push([daypartKey, item.value]);
        } else {
          nColumns.push([item.key, item.value]);
        }
      });

      this.columns = nColumns;
    },
    updateDonutChartHeaders() {
      const reportTypeLabel = this.reportTypeLabel || '';
      const reportTypeValue = this.reportTypeValue || '';
      const heads = [this.groupByValue, reportTypeLabel];
      const groupByName = this.groupByOptions.find((option) => option?.id === this.groupByValue);

      if (groupByName) {
        heads[0] = groupByName.name;
      }
      if (this.groupByValue === 'daypart') {
        heads[0] = 'Time';
      }
      heads[1] = `${heads[1]}${
        reportTypeLabel.toUpperCase() === CONSTANT.REVENUE.toUpperCase() ||
        reportTypeValue.toUpperCase() === CONSTANT.SPEND.toUpperCase()
          ? ' ($)'
          : ''
      }`;
      this.donutChart.legendHeaders = [heads];
      this.donutChart.showLegendHeaders = true;
      this.donutChart.showLegendValues = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen {
  .noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .opt-btn:hover {
    color: var(--primarycolor);
  }
}

::v-deep .dropdown {
  width: 220px !important;
}

::v-deep .c3-event-rect {
  cursor: auto !important;
}
.pan-by {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  font-size: 14px;
  color: #cad1d6;
}
.loading-spinner {
  width: 100px;
  height: 100px;
}
.container-chart {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin-top: 30px;
}
.chart-filters {
  display: flex;
  padding-bottom: 40px;
  border-bottom: 1px solid #31323a;
  margin-left: 13px;
  .chart-widget {
    position: absolute;
    right: 55px;
  }
  .flp-opt-panel {
    top: 40px;
    right: 30px;
  }
}
::v-deep .wrap-longtxt {
  width: auto;
  max-width: 275px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cur-amt {
  margin: 10px;
  font-size: 48px;
  font-weight: 300;
}
::v-deep .chart-filters .dropdown .dropdown-menu li {
  font-size: 14px;
}
@media screen and (max-width: 1290px) {
  ::v-deep .donut-container .donut-legend {
    width: 185px;
    overflow-x: scroll;
  }
}
.pan-head-icon-wrap {
  position: absolute;
  right: 17px;
  top: 11px;
}
.pan-head-icon {
  color: #97a7bb;
}
.pan-head-icon:hover {
  color: var(--primarycolor) !important;
}
.export-intip4 {
  position: relative;
  top: 0px;
  display: inline-block;
}
.light-theme {
  .export-intip4::after {
    background-color: rgba(55, 55, 60, 0.95);
    color: #fff;
  }
  .export-intip6[data-tool-tip]::after {
    background-color: rgba(55, 55, 60, 0.95);
    color: #fff;
    font-weight: 400 !important;
  }
}
.export-intip4[data-tool-tip]::after {
  position: absolute;
  bottom: 0%;
  left: 0;
  z-index: 99;
  display: inline-block;
  width: 100px;
  padding: 10px 12px;
  margin-left: -65px;
  font-size: 12px;
  line-height: 1.2em;
  color: #5b5b5b;
  text-align: center;
  pointer-events: none;
  content: attr(data-tool-tip);
  background-color: #35363c;
  border-radius: 3px;
  -webkit-transition: ease 0.3s;
  transition: ease 0.3s;
  -webkit-transform: scale(0);
  transform: scale(0);
  transform-origin: bottom center;
}
.export-intip4[data-tool-tip]::after {
  width: 140px;
  margin-left: -75px;
  font-size: 12px;
  color: #222;
  pointer-events: none;
  background-color: rgba(245, 245, 250, 0.9);
}
.export-intip6[data-tool-tip]::after {
  background-color: rgba(245, 245, 250, 0.9);
  color: #222;
  width: 70px;
  bottom: 100%;
  margin-left: -35px !important;
  font-size: 12px;
  font-weight: 600;
  padding: 6px;
  text-align: center;
  pointer-events: none;
}
.export-intip6[data-tool-tip]:hover::after {
  transform: scale(1);
  bottom: 145%;
}

.download-options-modal {
  position: absolute;
  top: 200%;
  left: -970%;
  transform: translate(-20%, -30%);
  background-color: #fff;
  padding: 8px 14px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 3;
  text-align: justify;
  width: 160px;
}

.options-content p {
  padding: 4px;
  font-size: 14px;
  color: #333;
}

.options-content button {
  margin: 5px;
  padding: 8px 15px;
  font-size: 14px;
  background-color: #00abb7;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.options-content button:hover {
  background-color: #007b8f;
}
.option-btn {
  padding: 10px;
}
.close-btn {
  background: white;
}

.modal-header {
  text-align: right;
}
</style>
